/* 
1 手机竖屏 没有滚动条 宽度100%

2 pad竖屏 、pad横屏  宽度固定 没有滚动条

3 手机横屏  宽度固定  有滚动条

高度:最小高度（完全显示内容），如果屏幕高度大于最小高度就上下居中，否则显示滚动条,min-height
宽度：pad 2,3像素比例，分别率，0.80,100.8,1440
*/

/* 兼容PC*/
@media screen and (min-device-width: 450px) {
  html {
    font-size: 100px;
  }
}

@media screen and (orientation : landscape) {
  .app-container {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
}

/* 兼容ipad横屏*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1380px) and (orientation : landscape){
  html {
    font-size: calc(100vw/9.6);
  }
}

/* 兼容ipad竖屏*/
@media only screen and (min-device-width : 768px) and (max-device-width : 1380px) and (orientation : portrait){
  html {
    font-size: calc(100vw/6);
  }
}


.background-container {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  /* background-attachment: fixed; */
}

.web-container {
  background-image: url("../assets/image/bg-web.png");
}

.mobile-container {
  background-image: url("../assets/image/bg-mobile.png");
}

.pad-horizon-container {
  background-image: url("../assets/image/bg-ipad.png");
}

.pad-vertical-container {
  background-image: url("../assets/image/bg-ipad-vertical.png");
}

.app-container {
  /* padding-top: 0.2rem; */
  /* height: 4.6rem; */
  width: 2.8rem;
  margin: auto;
  font-size: 0.16rem;
}

.logo-container .logo-image {
  margin: 0 auto;
  width: 0.8rem;
  height: 0.8rem;
}

.logo-container .logo-image img{
  width: 0.8rem;
  height: 0.8rem;
}

.logo-container .logo-name {
  margin-top: 0.16rem;
  font-size: 0.24rem;
  font-weight: bold;
  text-align: center;
}

.logo-container .logo-detail {
  margin-top: 0.08rem;
  font-size: 0.14rem;
  text-align: center;
}

.detail-en {
  width: 3rem;
}

.download-container {
  margin-top: 0.4rem;
}

.download-container .download-btn {
  margin: 0 auto;
  width: 2rem;
  height: 0.44rem;
  line-height: 0.44rem;
  border-radius: 0.22rem;
  background-color: #0077FF;
  color: #FFFFFF;
  font-size: 0.15rem;
  text-align: center;
}

.download-container .download-btn img{
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.05rem;
  margin-right: 0.04rem;
  width: 0.24rem;
  height: 0.24rem;
}

.download-container .download-version{
  margin-top: 0.16rem;
  text-align: center;
  font-size: 0.14rem;
  color: #737373;
}

.download-container .install-tips{
  margin-top: 0.1rem;
  text-align: center;
  font-size: 0.1rem;
  color: #737373;
}

.download-container .download-beta{
  margin-top: 0.16rem;
  text-align: center;
  font-size: 0.14rem;
  color: #0077FF;
}

.system-container {
  margin-top: 0.4rem;
}

.system-container .system-logo {
  width: 100%;
  display: inline-flex;
  justify-content: center;
}

.system-container .system-logo img{
  width: 0.40rem;
  height: 0.40rem;
  margin: 0 0.20rem;
}

.system-container .system-detail{
  margin-top: 0.16rem;
  text-align: center;
  font-size: 0.12rem;
  color: #737373;
}

.wx-mask{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.60);
}
.wx-mask img{
  width: 100%;
  display: block;
  margin: 0 auto;
}
